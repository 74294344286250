<template>
  <div class="ManageTokens">
    <div class="card">
      <div class="card-body">
        <b-row>
          <b-col xl="6" lg="6" md="6" sm="12" class="my-1">
            <b-form-group
              :label="$t('GENERAL.TOKEN')"
              label-for="filter-input"
              label-cols-sm="4"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-form-input id="filter-input" v-model="manageTokensFilter.token" type="search" :placeholder="$t('GENERAL.TOKEN')"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12" class="my-1">
            <b-form-group
              :label="$t('GENERAL.FILTER')"
              label-for="filter-input"
              label-cols-sm="4"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-form-input id="filter-input" v-model="manageTokensFilter.search" type="search" :placeholder="$t('GENERAL.FILTER')"></b-form-input>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12" class="my-1">
            <b-form-group
              :label="$t('GENERAL.STATUS')"
              label-for="filter-input"
              label-cols-sm="4"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-form-select
                id="per-page-select"
                v-model="manageTokensFilter.status"
                :options="statusOptions"
                text-field="name"
                value-field="id"
              ></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12" class="my-1">
            <b-form-group
              :label="$t('GENERAL.PER_PAGE')"
              label-for="per-page-select"
              label-cols-sm="4"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-form-select id="per-page-select" v-model="manageTokensFilter.perPage" :options="pageOptions"></b-form-select>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12" class="my-1">
            <b-form-group
              :label="$tc('GENERAL.PRODUCT', 2)"
              label-for="content-select"
              label-cols-sm="4"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-input-group class="">
                <b-form-select id="content-select" v-model="manageTokensFilter.content" :options="contents" text-field="name" value-field="id"></b-form-select>
                <b-input-group-append>
                  <b-button variant="outline-danger" @click="manageTokensFilter.content = ''">
                    <i class="fa fa-times"></i>
                  </b-button>
                </b-input-group-append>
              </b-input-group>
            </b-form-group>
          </b-col>
          <b-col xl="6" lg="6" md="6" sm="12" class="my-1">
            <b-form-group
              :label="$tc('GENERAL.GROUP', 2)"
              label-for="content-select"
              label-cols-sm="4"
              label-cols-md="4"
              label-cols-lg="3"
              label-align-sm="right"
              label-size="lg"
              class="mb-0"
            >
              <b-input-group class="">
                <vue-select v-model="manageTokensFilter.tag" :options="currentGroups" label="name" class="w-100" />
              </b-input-group>
            </b-form-group>
          </b-col>
        </b-row>

        <b-row>
          <b-col>
            <div class="d-flex justify-content-end mb-5 mt-2">
              <button class="btn btn-primary" @click="pageClick($event, 1)">
                <i class="fa fa-search"></i>
                {{ $t("GENERAL.SEARCH") }}
              </button>
            </div>
          </b-col>
        </b-row>
        <b-row>
          <b-col>
            <div class="d-flex justify-content-end mb-5 mt-2">
              {{ $t("GENERAL.FOUND") }}:
              {{ boughtTokensPagination.total }}
            </div>
          </b-col>
        </b-row>
        <!-- Main table element -->
        <b-row v-if="!loading">
          <b-col class="rounded bg-primary p-1 mt-4">
            <b-table
              class="m-0"
              :items="boughtTokens"
              :fields="fields"
              :current-page="1"
              :per-page="manageTokensFilter.perPage"
              stacked="md"
              show-empty
              :striped="striped"
              :bordered="bordered"
              :borderless="borderless"
              :outlined="outlined"
              :small="small"
              :hover="hover"
              :dark="dark"
              :fixed="fixed"
              :foot-clone="footClone"
              :no-border-collapse="noCollapse"
              :head-variant="headVariant"
              :table-variant="tableVariant"
              thead-class="head-primary"
            >
              <template #cell(token_token)="row">
                {{ row.value }}<br />
                <span class="text-danger font-weight-bolder font-size-lg" v-if="!row.item.token_progress">0 <i class="fa fa-percentage text-danger"></i></span>
                <div class="progress" v-if="row.item.token_progress > 0">
                  <div
                    class="progress-bar success"
                    role="progressbar"
                    :style="{
                      width: row.item.token_progress + '%',
                    }"
                    :aria-valuenow="row.item.token_progress"
                    aria-valuemin="0"
                    aria-valuemax="100"
                  >
                    {{ row.item.token_progress }} %
                  </div>
                </div>
              </template>

              <template #cell(token_redeem_date)="row">
                {{ row.value }}
                <br />
                {{ row.item.attendee_email }}
              </template>

              <template #cell(token_invite_email)="row">
                <span class="font-weight-bold"> {{ row.item.token_invite_name }}</span>
                <br />
                {{ row.value }}
              </template>

              <template #cell(actions)="row">
                <b-button v-if="false" size="sm" @click="info(row.item, row.index, $event.target)" class="mr-1"> Info modal </b-button>
                <b-button size="xs" @click="row.toggleDetails" class="btn btn-outline-success p-1">
                  <i class="fa fa-eye-slash" v-if="row.detailsShowing" style="padding: 0 !important"></i>
                  <i class="fa fa-eye" v-if="!row.detailsShowing" style="padding: 0 !important"></i>
                </b-button>
                <b-button size="xs" class="btn btn-outline-info ml-1 p-1 mr-1" @click="copyURL(row)">
                  <i class="fa fa-ticket-alt" style="padding: 0 !important"></i>
                </b-button>

                <b-button
                  v-if="row.item.content_has_report && row.item.token_progress === 100"
                  size="xs"
                  class="btn btn-outline-danger ml- p-1"
                  @click="onReportClick($event, row)"
                >
                  <i class="fa fa-file-pdf" style="padding: 0 !important" v-if="!loadingReport"></i>
                  <i class="fa fa-spin fa-spinner" style="padding: 0 !important" v-else></i>
                </b-button>
                <b-button
                  v-if="row.item.content_has_report && row.item.token_progress === 100"
                  size="xs"
                  class="btn btn-success ml-1 p-1"
                  @click="onReportPreviewClick($event, row)"
                >
                  <i class="fa fa-search" style="padding: 0 !important" v-if="!loadingReport"></i>
                  <i class="fa fa-spin fa-spinner" style="padding: 0 !important" v-else></i>
                </b-button>
              </template>

              <template #row-details="row">
                <manage-tokens-details :row="row"></manage-tokens-details>
              </template>
            </b-table>
          </b-col>
        </b-row>
        <b-row class="center" v-if="loading">
          <b-col xl="12" lg="12" md="12" sm="12" class="my-1 text-center">
            <i class="fa fa-cog fa-spin fa-2x"></i>
          </b-col>
        </b-row>

        <b-row v-if="boughtTokens.length > 0 && !loading">
          <b-col sm="12" md="12" class="my-1">
            <la-paginator :pagination="boughtTokensPagination" @page-click="pageClick"></la-paginator>
          </b-col>
        </b-row>
      </div>
    </div>

    <!-- Info modal -->
    <b-modal :id="infoModal.id" :title="infoModal.title" ok-only @hide="resetInfoModal">
      <pre>{{ infoModal.content }}</pre>
    </b-modal>

    <b-modal
      id="modal-report-preview"
      :title="infoModal.title"
      @ok="onReportPreviewOk"
      @cancel="onReportPreviewCancel"
      :ok-title="$t('GENERAL.CONFIRM')"
      :cancel-title="$t('GENERAL.CANCEL')"
      size="xl"
      button-size="sm"
    >
      <iframe
        :src="objectURL"
        type="application/pdf"
        width="100%"
        height="100%"
        style="overflow: auto"
        class="border-0 min-h-600px min-w-600px"
        v-if="!loadingReport"
      >
      </iframe>
      <i class="fa fa-spin fa-spinner black" style="padding: 0 !important" v-else></i>
      <template #modal-footer="{ cancel }">
        <b-button size="sm" variant="success" @click="cancel()"> {{ $t("GENERAL.CONFIRM") }}</b-button>
      </template>
    </b-modal>
  </div>
</template>

<style>
.width-120 {
  width: 120px;
}

.head-primry tr th {
  background-color: #6cabfc !important;
}
</style>
<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import LaPaginator from "@/view/pages/components/LaPaginator/LaPaginator";
import { GET_BOUGHT_TOKENS } from "@/core/services/store/admin-tokens.module";
import { GET_CONTENTS } from "@/core/services/store/contents.module";
import ManageTokensDetails from "@/view/pages/tokens/ManageTokens/components/ManageTokensDetails/ManageTokensDetails";
import TheToaster from "@/core/services/the-toaster";
import store from "@/core/services/store";
import { GET_DOWLOADABLE_TAG_REPORT, GET_TAGS_FULL_LIST } from "@/core/services/store/admin-token-tags.module";
import { GET_DOWLOADABLE_REPORT, GET_REPORT } from "@/core/services/store/report.module";

export default {
  name: "manage-tokens",
  components: { ManageTokensDetails, LaPaginator },
  computed: {
    ...mapGetters({
      tagsFullList: "adminTokenTags/tagsFullList",
      getTagById: "adminTokenTags/getById",
      currentUser: "auth/currentUser",
      currentUserPicture: "auth/currentUserPicture",
      contents: "contents/contents",
      boughtTokensPagination: "adminTokens/boughtTokensPagination",
      boughtTokens: "adminTokens/boughtTokens",
      manageTokensFilter: "adminTokens/manageTokensFilter",
    }),

    currentGroups: {
      cache: false,
      get() {
        return this.tagsFullList;
      },
    },
    sortOptions() {
      // Create an options list from our fields
      return this.fields
        .filter((f) => f.sortable)
        .map((f) => {
          return { text: f.label, value: f.key };
        });
    },
  },
  data() {
    return {
      pageOptions: [20, 30, 50, 100],

      tableVariants: ["primary", "secondary", "info", "danger", "warning", "success", "light", "dark"],
      striped: true,
      bordered: true,
      borderless: false,
      outlined: false,
      small: true,
      hover: true,
      dark: false,
      fixed: true,
      footClone: false,
      headVariant: "dark",
      loading: false,
      tableVariant: "secondary",
      objectURL: "",
      noCollapse: false,
      loadingReport: false,
      currentPage: 1,
      statusOptions: [
        { name: this.$t("GENERAL.ALL"), id: "all" },
        { name: this.$t("GENERAL.INVITED"), id: "invited" },
        { name: this.$t("GENERAL.NOT_REDEEMED"), id: "not_redeemed" },
        { name: this.$t("GENERAL.REDEEMED"), id: "redeemed" },
        { name: this.$t("GENERAL.STARTED"), id: "started" },
        { name: this.$t("GENERAL.FINISHED"), id: "finished" },
        { name: this.$t("GENERAL.NOT_USED"), id: "not_used" },
      ],
      fields: [
        {
          key: "token_token",
          label: this.$t("GENERAL.TOKENS"),
          sortable: true,
          sortDirection: "asc",
        },
        {
          key: "token_invite_email",
          label: this.$t("GENERAL.INVITE_EMAIL"),
          sortable: true,
          class: "text-left",
        },
        {
          key: "token_redeem_date",
          label: this.$t("GENERAL.REDEEM_DATE"),
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        {
          key: "content_name",
          label: this.$t("GENERAL.CONTENT"),
          sortable: true,
          sortByFormatted: true,
          filterByFormatted: true,
        },
        { key: "actions", label: "Actions", class: "width-120" },
      ],

      infoModal: {
        id: "info-modal",
        title: "",
        content: "",
      },
    };
  },
  methods: {
    copyURL(row) {
      const el = document.createElement("textarea");
      el.value = row.item.token_token;
      el.setAttribute("readonly", "");
      el.style.position = "absolute";
      el.style.left = "-9999px";
      document.body.appendChild(el);
      const selected = document.getSelection().rangeCount > 0 ? document.getSelection().getRangeAt(0) : false;
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
      if (selected) {
        document.getSelection().removeAllRanges();
        document.getSelection().addRange(selected);
      }
      TheToaster.success("GENERAL.COPIED");
    },

    info(item, index, button) {
      this.infoModal.title = ` ${index}`;
      this.infoModal.content = JSON.stringify(item, null, 2);
      this.$root.$emit("bv::show::modal", this.infoModal.id, button);
    },
    resetInfoModal() {
      this.infoModal.title = "";
      this.infoModal.content = "";
    },
    pageClick(event, page) {
      this.currentPage = page;
      this.loading = true;
      this.$store
        .dispatch("adminTokens/" + GET_BOUGHT_TOKENS, {
          page: page,
          status: this.manageTokensFilter.status,
          search: this.manageTokensFilter.search,
          token: this.manageTokensFilter.token,
          tag_id: this.manageTokensFilter.tag?.id || null,
          content_id: this.manageTokensFilter.content,
          per_page: this.manageTokensFilter.perPage,
        })
        .then(() => {})
        .catch(() => {})
        .finally(() => {
          this.loading = false;
        });
    },
    onReportClick(e, row) {
      this.infoModal.title = this.$t("GENERAL.REPORT") + ": " + row.item.token_token + ": " + row.item.attendee_name;
      this.loadingReport = true;
      this.$store
        .dispatch("report/" + GET_REPORT, {
          token: row.item.token_token,
          filename: this.$laUtils.slugIt("Report " + row.item.content_codename + " " + row.item.attendee_name),
        })
        .finally(() => {
          this.loadingReport = false;
        });

      // console.log(e);
    },
    onReportPreviewOk(e) {
      // console.log("preview ok");
    },
    onReportPreviewCancel(e) {
      // console.log("preview cancel");
    },
    onReportPreviewClick(e, row) {
      this.infoModal.title = this.$t("GENERAL.REPORT") + ": " + row.item.token_token + ": " + row.item.attendee_name;
      this.$root.$emit("bv::show::modal", "modal-report-preview", e.target);
      this.loadingReport = true;
      this.$store
        .dispatch("report/" + GET_DOWLOADABLE_REPORT, {
          token: row.item.token_token,
          filename: this.$laUtils.slugIt("Report " + row.item.codename + " " + row.item.attendee_name),
        })
        .then((response) => {
          let blob = new Blob([response.data], { type: "application/pdf" });
          let URL = window.webkitURL || window.URL;
          this.objectURL = URL.createObjectURL(blob);
          this.loadingReport = false;
        })
        .finally(() => {
          self.loadingReport = false;
        });
      // console.log(e);
    },
  },

  mounted() {
    if (!this.currentUser.white_label_enabled) {
      this.$router.push({ name: "dashboard" });
    }
    this.$store.dispatch(SET_BREADCRUMB, [{ title: this.$t("GENERAL.MANAGE_TOKENS") }]);
    this.$store.dispatch("contents/" + GET_CONTENTS).then((response) => {
      this.$store.dispatch("adminTokenTags/" + GET_TAGS_FULL_LIST).then((response) => {
        if (this.$route.query.tag) {
          this.manageTokensFilter.tag.id = this.$route.query.tag || null;
          this.manageTokensFilter.tag.name = this.getTagById(this.$route.query.tag).name;
          this.pageClick(null, this.currentPage);
        }
        if (this.boughtTokens.length > 0) {
          this.pageClick(null, this.currentPage);
        }
      });
    });
  },
};
</script>
